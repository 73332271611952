import { useNavigation } from "@/hooks";
import Version from "@/layout/components/version.component.tsx";
import { NavLink } from "react-router-dom";

interface Props {
  className: string;
}

export default function Footer({ className = "" }: Props) {
  const navigation = useNavigation("footer");

  return (
    <footer className={`layout-footer ${className}`}>
      <div className="flex sm:flex-row flex-col justify-center sm:items-center gap-x-0 gap-y-4 sm:gap-y-0 sm:gap-x-6 p-4 text-xs">
        {navigation.map((i) => (
          <NavLink to={i.to} key={i.to}>
            {i.text}
          </NavLink>
        ))}
      </div>
      <Version />
    </footer>
  );
}
