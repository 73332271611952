import { AppSidebar } from "@/components/custom/app-sidebar.component";
import { SidebarProvider, SidebarTrigger } from "@/components/ui/sidebar";
import { useSystem } from "@/hooks";
import { Outlet } from "react-router-dom";

export function AppLayout() {
  const { data, setData } = useSystem();
  return (
    // <div id="app-layout" className="flex flex-row h-full">

    //   <Sidebar />
    //   <Scrollbar className="flex pl-16 sm:pl-0 w-full overflow-y-auto grow">
    //     <Outlet />
    //   </Scrollbar>
    // </div>
    <SidebarProvider open={!!data?.sidebar}>
      <AppSidebar />
      <main className="block w-full min-h-svh">
        <div className="relative h-0">
          <SidebarTrigger className="m-1 -ml-0" onClick={() => {
            setData({ sidebar: !data?.sidebar });
          }} />
        </div>
        <div className="w-full h-full">
          <Outlet />
        </div>
      </main>
    </SidebarProvider>
  );
}
