import { Icon } from "@/components/custom/icon.component.tsx";
import { BadgeCheck, Bell, CreditCard, LogOut, Sparkles } from "lucide-react";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

type Link = {
  text: string;
  to: string;
  icon?: ReactNode;
  roles?: string[];
  children?: Link[];
  defaultOpen?: boolean;
};

// items: {
//   title: string
//   url: string
//   icon?: LucideIcon
//   isActive?: boolean
//   items?: {
//       title: string
//       url: string
//   }[]
// }[]

export const useNavigation = (
  relation: "main" | "sidebar" | "account" | "footer" = "main",
): Link[] => {
  const { t } = useTranslation();
  return (
    {
      main: [
        {
          text: t("About Us"),
          to: "/about",
        },
        {
          text: t("How To?"),
          to: "/howto",
        },
      ],
      sidebar: [
        {
          text: t("Projects"),
          to: "/projects",
          icon: <Icon aria-hidden="true">topic</Icon>,
        }, {
          text: t("Applications"),
          to: "/applications",
          icon: <Icon aria-hidden="true">apps</Icon>,
        },
        {
          text: t("Users"),
          to: "/users",
          icon: <Icon aria-hidden="true">group</Icon>,
        },
        {
          text: t("Notes"),
          to: "/notes",
          icon: <Icon aria-hidden="true">docs</Icon>,
        },
      ],
      account: [
        {
          text: t("Upgrade Pro"),
          to: "/upgrade",
          icon: <Sparkles />,
        },

        {
          text: t("Account"),
          to: "/account",
          icon: <BadgeCheck />,
        },
        {
          text: t("Billing"),
          to: "/billing",
          icon: <CreditCard />,
        },
        {
          text: t("Notifications"),
          to: "/notifications",
          icon: <Bell />,
        },
        // {
        //   text: t('Change Password'),
        //   to: '/settings/account/password',
        //   icon: 'icon-password',
        //   roles: [],
        // },
        // {
        //   text: t('Set Username'),
        //   to: '/settings/account/username',
        //   icon: 'icon-username',
        //   roles: [],
        // },
        // {
        //   text: t('Change Email'),
        //   to: '/settings/account/email',
        //   icon: 'icon-email',
        //   roles: [],
        // },
        // {
        //   text: t("Lock"),
        //   to: "/lock",
        //   icon: <i className="icon-lock" />,
        // },
        {
          text: t("Logout"),
          to: "/logout",
          icon: <LogOut />,
        },
      ],
      footer: [
        {
          text: t("About Us"),
          to: "/about",
        },
        {
          text: t("How To?"),
          to: "/howto",
        },
        {
          text: t("Terms & Conditions"),
          to: "/tos",
        },
        {
          text: t("Privacy Policy"),
          to: "/privacy",
        },
        {
          text: t("Cookie Policy"),
          to: "/cookies",
        },
      ],
    }[relation] || [
      {
        text: t("Undefined Nav"),
        to: "/test",
        icon: "icon-ok",
        roles: [],
      },
    ]
  );
};
