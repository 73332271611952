import "i18next";
import i18n from "i18next";
import detector from "i18next-browser-languagedetector"; // https://github.com/i18next/react-i18next/issues/1587
import backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n
  .use(backend)
  .use(detector)
  .use(initReactI18next)
  .init({
    // supportedLngs: ["tr", "bg", 'gr', 'en'],
    supportedLngs: ["tr", "en"],
    fallbackLng: "tr",
    debug: false,
    detection: {
      lookupQuerystring: "lng",
      caches: ["localStorage", "cookie"],
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    returnNull: false,
  });

export default i18n;
