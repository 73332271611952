export const Logo = ({
  className,
  isCompact = false,
}: {
  className?: string;
  isCompact?: boolean;
}) => (
  <svg
    x="0"
    y="0"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox={isCompact ? "100 0 160 175" : "0 0 800 175"}
    xmlSpace="preserve"
    className={className}
  >
    <defs>
      <linearGradient id="logo-gradient" x1="0%" y1="0%" x2="300%" y2="0%">
        <stop offset="0%" stopColor="#14B8A6">
          <animate
            attributeName="stop-color"
            values="#14B8A6; #A855F7; #F97316"
            dur="4s"
            repeatCount="indefinite"
          />
        </stop>
        {/*<stop offset='50%' stopColor='#A855F7' />*/}
        <stop offset="100%" stopColor="#F97316">
          <animate
            attributeName="stop-color"
            values="#F97316; #A855F7; #14B8A6"
            dur="4s"
            repeatCount="indefinite"
          />
        </stop>
      </linearGradient>
      <clipPath id="logo-clip-path">
        <path
          d="M450.8,20.3l-38.2,52.4c-3.1,4.2-9.7,2-9.7-3.2V23.4c0-3-2.4-5.4-5.4-5.4h-15c-3,0-5.4,2.4-5.4,5.4v142.1
		c0,3,2.4,5.4,5.4,5.4h15c3,0,5.4-2.4,5.4-5.4v-35.3c0-1.2,0.3-2.2,1.1-3.2l8.7-11.9c1.7-2.3,4.9-2.9,7.3-1.4l25.3,16.3
		c1.6,1,2.4,2.7,2.4,4.5v31c0,3,2.4,5.4,5.4,5.4h15c3,0,5.4-2.4,5.4-5.4v-42.8c0-1.8-1-3.5-2.4-4.5l-36.5-23.5
		c-2.6-1.7-3.3-5.2-1.5-7.7l43.9-60.4c2.5-3.6,0-8.6-4.3-8.6h-17.4C453.4,18,451.8,18.9,450.8,20.3z"
        />
        <path
          d="M96.2,17.5H78.8c-1.7,0-3.4,0.8-4.3,2.2L35.5,73.2c-3.1,4.2-9.7,2-9.7-3.2V22.9c0-3-2.4-5.4-5.4-5.4h-15
		c-3,0-5.4,2.4-5.4,5.4V166c0,3,2.4,5.4,5.4,5.4h15c3,0,5.4-2.4,5.4-5.4v-35.3c0-1.2,0.3-2.2,1.1-3.2l8.7-11.9
		c1.7-2.3,4.9-2.9,7.3-1.4l25.3,16.3c1.6,1,2.4,2.7,2.4,4.5v31c0,3,2.4,5.4,5.4,5.4h15c3,0,5.4-2.4,5.4-5.4v-42.8
		c0-1.8-1-3.5-2.4-4.5L57.4,95.2c-2.6-1.7-3.3-5.2-1.5-7.7l44.7-61.4C103.2,22.6,100.7,17.5,96.2,17.5z"
        />
        <path
          d="M637.2,43.1h39.7c3,0,5.4-2.4,5.4-5.4v-15c0-3-2.4-5.4-5.4-5.4h-65.5c-3,0-5.4,2.4-5.4,5.4v86.6v25.8v31.2
		c0,3,2.4,5.4,5.4,5.4h15c3,0,5.4-2.4,5.4-5.4v-25.8c0-3,2.4-5.4,5.4-5.4h24.5c3,0,5.4-2.4,5.4-5.4v-15c0-3-2.4-5.4-5.4-5.4h-24.4
		c-3,0-5.4-2.4-5.4-5.4V48.5C631.8,45.5,634.2,43.1,637.2,43.1z"
        />
        <path
          d="M562.9,17.3H517c-2.5,0-4.7,1.7-5.3,4.2l-19.9,86.2c-0.1,0.4-0.1,0.8-0.1,1.2v57.5c0,3,2.4,5.4,5.4,5.4h15
		c3,0,5.4-2.4,5.4-5.4v-27c0-3,2.4-5.4,5.4-5.4h33.9c3,0,5.4,2.4,5.4,5.4v27c0,3,2.4,5.4,5.4,5.4h15c3,0,5.4-2.4,5.4-5.4v-57.6
		c0-0.4,0-0.8-0.1-1.2L568,21.4C567.5,19,565.4,17.3,562.9,17.3z M538.7,43.1h2.3c2.5,0,4.8,1.8,5.3,4.2l12.1,54.4
		c0.7,3.4-1.8,6.6-5.3,6.6h-26.4c-3.5,0-6-3.2-5.3-6.6l12.1-54.4C534.1,44.8,536.2,43.1,538.7,43.1z"
        />
        <path
          d="M755.7,17.3h-45.9c-2.5,0-4.7,1.7-5.3,4.2l-19.9,86.2c-0.1,0.4-0.1,0.8-0.1,1.2v57.5c0,3,2.4,5.4,5.4,5.4h15.1
		c3,0,5.4-2.4,5.4-5.4v-27c0-3,2.4-5.4,5.4-5.4h33.9c3,0,5.4,2.4,5.4,5.4v27c0,3,2.4,5.4,5.4,5.4h15c3,0,5.4-2.4,5.4-5.4v-57.6
		c0-0.4,0-0.8-0.1-1.2L761,21.4C760.5,19,758.3,17.3,755.7,17.3z M731.6,43.1h2.3c2.5,0,4.8,1.8,5.3,4.2l12.1,54.4
		c0.7,3.4-1.8,6.6-5.3,6.6h-26.4c-3.5,0-6-3.2-5.3-6.6l12.1-54.4C727,44.8,729.2,43.1,731.6,43.1z"
        />
        <path
          d="M274.8,17.6h-7.5c-3,0-5.4,2.4-5.4,5.4v142.9c0,3,2.4,5.4,5.4,5.4h7.5c46.5,0,84.3-34.6,84.3-77.2S321.2,17.6,274.8,17.6z
			 M294.8,145c-3.5,1.2-7.1-1.5-7.1-5.1V48.4c0-3.7,3.6-6.3,7.1-5.1c22.4,7.6,38.5,27.5,38.5,50.9S317.2,137.4,294.8,145z"
        />
        <ellipse cx="177.1" cy="94.5" rx="65.6" ry="78.7" />
      </clipPath>
    </defs>
    <rect
      width="1157.4"
      height="243"
      clipPath="url(#logo-clip-path)"
      className="fill-[#3F3F46] dark:fill-[url(#logo-gradient)]"
    />
    <path
      d="M177.7,154.1c-8.5,0-20.7-1.9-30.8-10.9c-11.3-10-17-26.5-17-48.9c0-33.4,20.7-59.5,47.3-59.5c21.5,0,34.7,13.9,41.5,43.5
			c0.4,1.8,5.3,14.2,6.7,16.5c2.3,4.1,1.9,9.3-1.2,12.9c-1.9,2.2-4.4,3.1-6.2,3.6l0,0c0.5,11.1,1.2,21.7-8.2,30.3
			C201.3,149.5,189.5,154.1,177.7,154.1z"
    />
    <path
      className="fill-white dark:fill-black"
      d="M177.2,37.8c19.9,0,32,13.1,38.5,41.1c0.4,2,5.5,14.7,7,17.3c1.7,3.1,1.4,6.9-0.8,9.5
			c-1.4,1.7-3.4,2.2-5.1,2.6c-0.6,0.2-1.6,0.4-2,0.6c0,0,0,0.1,0,0.2c0,0.7,0.1,1.4,0.1,2.1c0.5,10.9,1.1,20.3-7.3,28
			c-7.7,7.2-18.9,11.5-29.8,11.5c-7.9,0-19.5-1.8-28.8-10c-10.7-9.5-16.1-25.2-16.1-46.6C132.9,62.7,152.4,37.8,177.2,37.8
			 M177.2,31.9c-28.1,0-50.2,27.5-50.2,62.6c0,23.2,6,40.4,18.1,51.1c10.8,9.6,23.8,11.6,32.8,11.6c12.5,0,25.2-5,33.9-13.1
			c9.7-9.1,9.8-19.9,9.3-30.5c1.8-0.6,3.8-1.8,5.4-3.8c3.8-4.5,4.4-11.1,1.5-16.3c-1.2-2.1-5.8-13.7-6.3-15.8
			C214.4,46.9,200,31.9,177.2,31.9L177.2,31.9z"
    />
    <path
      className="fill-white"
      d="M211.8,109.6c0.5,11.7,1.6,20.5-6.2,27.8c-7.2,6.8-17.6,10.8-27.8,10.8c-15.6,0-41.8-7-41.8-53.7
		c0-30,18.1-53.6,41.2-53.6c19.2,0,29.8,13.6,35.5,38.8c0.5,2.4,5.6,15.2,7.3,18.1c1.2,2,0.8,4.4-0.5,6.1
		C217.5,106.3,211.6,105.1,211.8,109.6z"
    />
    <g className="fill-black">
      <line x1="211.1" y1="89.4" x2="214.2" y2="89.4" />
      <path
        d="M214,87.5h-1.4l0,0c-0.6-2-2.2-3.7-4.4-4.5c-0.7-0.2-1.6-0.3-2.3-0.3h-12.8h-1.2h-20.1c-3.2,0-6.2,1.3-8.5,3.5l-6.7,6.7
				c-0.8,0.8-0.8,2.2,0,3c0.3,0.3,0.7,0.4,1.2,0.4c0.5,0,1-0.2,1.4-0.5l6.8-6.8c1.6-1.6,3.7-2.4,5.8-2.4h11c1.9,0,4,14.2,4,14.2
				c0.5,3.1,3.2,5.3,6.1,5.6l11,0.1c0.2,0,0.4,0,0.6,0c3.5,0,6.5-2.5,6.9-6l1.3-9h1.4c1.2,0,2-1,1.9-2.1
				C215.9,88.2,215,87.5,214,87.5z M207.9,99.8c-0.2,1.7-1.8,3-3.5,2.9l-11-0.1c-1.4-0.2-2.5-1.3-2.7-2.6l-1.9-9.6
				c-0.2-1,0.1-1.9,0.6-2.6c0.6-0.7,1.5-1.2,2.5-1.2h1.2H206c0.5,0,1.2,0.1,1.6,0.4c1,0.5,1.5,1.5,1.7,2.4c0,0.1,0,0.1,0,0.2
				c0,0.1,0,0.3,0,0.4c0,0.1,0,0.1,0,0.2L207.9,99.8z"
      />
    </g>
  </svg>
);
