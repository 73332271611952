import { Select, SelectContent, SelectTrigger, SelectValue } from "@/components/ui/select";
import localization from "@/localization";
import { SelectItem } from "@radix-ui/react-select";

type Props = {
  className?: string;
  short?: boolean;
};

export function LocaleSwitcher({ className = "", short = true }: Props) {
  const locale = localization?.language;
  const locales = localization?.options?.supportedLngs as string[];
  const localesMap = (k: string) =>
    ({
      en: "🇬🇧 English",
      tr: "🇹🇷 Türkçe",
      bg: "🇧🇬 Български",
      gr: "🇬🇷 Ελληνικά",
    })[k] || "";

  return locales?.length > 1 ? (
    <div className={`whitespace-nowrap ${className}`}>
      <Select
        defaultValue={locale}
        // className='bg-transparent pr-6 border-none uppercase'
        // className="border-0 bg-transparent py-1.5 pr-7 pl-3 focus:ring-0 text-neutral-800 sm:text-sm dark:text-amber-50 uppercase sm:leading-6 outline-hidden"
        onValueChange={(e) => localization.changeLanguage(e)}
      >
        <SelectTrigger className="border-none w-[120px]">
          <SelectValue placeholder="Language">{short ? locale : localesMap(locale)}</SelectValue>
        </SelectTrigger>
        <SelectContent>
          {(locales || [])
            .filter((i: string) => i != "cimode")
            .map((i: string) => (
              <SelectItem key={i} value={i} className="p-2 text-sm uppercase">
                {short ? i : localesMap(i)}
              </SelectItem>
            ))}
        </SelectContent>
      </Select>
    </div>
  ) : (
    <></>
  );
}
