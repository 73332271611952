import { useTheme } from "@/hooks";
import CookieBanner from "@/layout/components/cookie-banner.component.tsx";
import LineLoader from "@/layout/components/line-loader.component.tsx";
import System from "@/layout/components/system.component.tsx";
import Version from "@/layout/components/version.component.tsx";
import { LoadingFallback } from "@/layout/Loading.fallback.tsx";
import { Analytics } from "@vercel/analytics/react";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";

export function SystemLayout() {
  const { theme } = useTheme();
  // const location = useLocation();

  return (
    <div className="h-full min-h-screen flex flex-col" data-theme={theme}>
      <Suspense fallback={<LoadingFallback />}>
        <LineLoader />
        <System />
        <Outlet />
        <CookieBanner />
        <Version />
        {/*<ReloadPrompt />*/}
        <Analytics />
      </Suspense>
    </div>
  );
}
